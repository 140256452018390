.__oneui__file__dropzone__e780e {
  cursor: pointer;
}

.__oneui__file__dropzone__e780e .dropzone {
  min-height: auto;
}

.__oneui__file__dropzone__e780e .dz-message {
  padding: 0 2em;
  text-align: center;
}
