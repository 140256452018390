.__oneui__modal__entry__63e99 .control-label {
  text-transform: capitalize;
}

.__oneui__modal__entry__63e99 .has-error {
  .ember-basic-dropdown-trigger {
    border-color: #d26a5c;
  }
  .ember-power-select-status-icon {
    display: none;
  }

  .form-control-feedback:after {
    font-family: FontAwesome;
    content: "\f00d";
  }
}

.__oneui__modal__entry__63e99 .has-success {
  .ember-basic-dropdown-trigger {
    border-color: #34a263;
  }
  .ember-power-select-status-icon {
    display: none;
  }

  .form-control-feedback:after {
    font-family: FontAwesome;
    content: "\f00c";
  }
}
