@import "ember-bootstrap/bootstrap";
@import "ember-power-select/style";
@import "oneui/style";
@import "pod-styles";

html {
  font-size: inherit;
}

.fish-file-logo {
  width: 50px;
  height: 50px;
}

.neso-logo {
  height: 50px;
  width: 100px;
}

#main-container {
  height: 92vh;
}

.control-label {
  text-transform: capitalize;
}
