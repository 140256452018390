.__transaction__io-summary__47d2a {
  margin: 20px 0px;
  width: 100% !important;

  tfoot {
    td:last-child {
      border-top: 1px solid #a48ad4;
    }
  }
}
