$ember-power-select-focus-outline: none !default;
$ember-power-select-search-field-focus-border: 1px solid #ccc !default;

@import "../ember-power-select";

.ember-power-select-trigger {
  padding: 4px;
  border-color: #e6e6e6;
  &focus {
    border-color: #ccc;
  }
}

.ember-power-select-clear-btn {
  color: #ccc;
}

.ember-basic-dropdown-content {
  border-color: #e6e6e6;
}

.has-error .ember-power-select-trigger {
  border-color: #d26a5c;
}
