.data-table-pagination {
  .data-table-info {
    padding: 25px 0px;

    .ember-power-select-trigger {
      display: inline-flex;
      margin: 0px 5px;
      padding-right: 22px;
    }
  }
}
