.__bm-dashboard__3f4cf #page-footer {
  text-align: center;

  a {
    margin: 10px;
  }
}

.__bm-dashboard__3f4cf .simple-bar-scroll {
  height: 100%;

  .nav-main {
    margin-bottom: 80px;
  }
}
